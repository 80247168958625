import { Navigate } from '@tanstack/react-router'
import type { ReactElement } from 'react'
import { useSession } from '../../lib/session'
import HomeUnconnectedPage from './home-unconnected-page'

export default function HomePage(): ReactElement {
  const session = useSession()

  if (!session.user) return <HomeUnconnectedPage />

  if (session.permissions.type === 'client') return <Navigate to="/home" />
  if (session.permissions.type === 'partner')
    return (
      <Navigate
        to="/partner/$organization/home"
        params={{ organization: session.permissions.memberships[0].organization.technicalName }}
      />
    )
  if (session.permissions.type === 'platform') return <Navigate to="/bak/home" />

  return <HomeUnconnectedPage />
}
